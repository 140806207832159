import React from "react";

import portret from "../assets/img/portret.png";

export default function Home() {
    return (
        <div>
            <div >
                <p style={{"textAlign":"center"}}>
                    <img src={portret} alt="..." className="portrait align-middle" />
                </p>
                <h1>About Me</h1>
                <h3>Hi. I'm Paweł Mstowski and I work as a software developer.</h3>
                <p>I have 6 years of experience with dotnet platform and C#. I have worked with test automation, monolith web applications and distributed systems with microservice architecture. During last 3 years I was involved in migrating to kubernetes and improwing develompment process for Hitachis Lumada APM (formerly known as ABB Ellipse APM). Additionaly I really like programming in Python which I use to automate many boring tasks in my job.</p>
                <p>After work I like to go hiking, sleep in tent or run RPG sessions in "Dungeons & Dragons", "Call of Cthulhu" or "Tales from the Loop".</p>
            </div>
        </div>
    );
}
