import React, { useState } from "react";

export default function Footer() {
    return (
        <footer>
            <div className="container">
                <div className="row">
                    <div className="col">
                        <a href="https://www.linkedin.com/in/pawe%C5%82-mstowski-07904ab2/" target="_blank"><i className="bi bi-linkedin"></i></a>
                        <a href="https://github.com/mustafmst" target="_blank" rel="noopener noreferrer"><i className="bi bi-github"></i></a>
                    </div>
                    <div className="col">
                        <small>Copyright &copy; 2021,  <a href="http://mstowski.pl">Paweł Mstowski</a> </small>
                    </div>
                </div>
                <div className="row">
                    <div className="col" style={{"textAlign":"center", "fontSize":"0.45em"}}>
                    <a href="https://foundry.mstowski.pl" target="_blank" rel="noopener noreferrer" title="Open the doors to another world."><i className="bi bi-door-open"></i></a>
                    </div>
                </div>
            </div>
        </footer>
    );
}
