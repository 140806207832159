import React from "react";

export default function Experience() {
    return (
        <div className="row">
            <div className="col">
                <h1>Experience Section</h1>
            </div>
        </div>
    );
}