import React from 'react';

export default function NavBar(props) {
    return (
        <ul className="nav justify-content-center">
            {Object.keys(props.sections).map(section => {
                return (
                    <li key={section} className="nav-item">
                        <a className="nav-link" aria-current="page" href="#" onClick={() => props.setSection(section)}>{section}</a>
                    </li>
                );
            })}
        </ul>
    );
};