import React, { useState } from "react";

import Nav from "./nav";
import Footer from "./footer";
import Home from "./home";
import Experience from "./experience";
import Education from "./education";

export default function App() {

    const [activeSection, setActiveSection] = useState("Home");

    var sections = {
        "Home": <Home />,
        // "Experience": <Experience />,
        // "Education": <Education />
    }


    return (
        <div>
            <Nav sections={sections} setSection={setActiveSection} activeSection={activeSection} />
            <div className="container-md" id="content">
                {sections[activeSection]}
            </div>
            <Footer />
        </div>
    );
};
